import { Box } from "@material-ui/core";

import { Footer, Sidebar, Navbar } from "views/layouts";

const DefaultLayout = ({ children }) => {
  return (
    <Box>
      <Sidebar />
      <Navbar />
      {children}
      <Footer />
    </Box>
  );
};

export default DefaultLayout;
