import { Link } from "react-router-dom";
import { Box, List, ListItem, makeStyles, Theme } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    position: "absolute",
    left: "0",
    top: "0",
    width: "120px",
    height: "100vh",
    zIndex: 10,
    flexDirection: "column",
    textAlign: "center",
    borderRight: "1px solid rgba(255, 255, 255, 0.1)",
    padding: "30px 0",
    transition: "all 0.3s",
    transitionDuration: "500ms",
    transitionDelay: "1s",
    transitionTimingFunction: "cubic-bezier(0.86, 0, 0.07, 1);",
    [theme.breakpoints.down("md")]: {
      width: "80px",
      borderRight: "none",
      paddingBottom: 13,
    },
  },
  logo: {
    display: "inline-block",
  },
  logoImage: {
    height: 90,
    [theme.breakpoints.down("md")]: {
      height: 65,
    },
  },
  socialLink: {
    justifyContent: "center",
  },
}));

const Sidebar: React.FC = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <div className={styles.logo}>
        <Link to="/">
          <img
            className={styles.logoImage}
            src="/assets/images/logo-white.png"
            alt="Logo"
          />
        </Link>
      </div>
      <div>
        <List>
          <ListItem className={styles.socialLink}>
            <FacebookIcon />
          </ListItem>
          <ListItem className={styles.socialLink}>
            <LinkedInIcon />
          </ListItem>
          <ListItem className={styles.socialLink}>
            <InstagramIcon />
          </ListItem>
          <ListItem className={styles.socialLink}>
            <TwitterIcon />
          </ListItem>
        </List>
      </div>
    </Box>
  );
};

export default Sidebar;
