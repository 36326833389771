import { Box, Button, Typography } from "@material-ui/core";

const NotFound = () => {
  return (
    <Box>
      <Typography variant="h5">404</Typography>
      <Typography variant="subtitle2">Oops, page not found.</Typography>
      <Button href="/">Back Home</Button>
    </Box>
  );
};

export default NotFound;
