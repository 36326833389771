import {
  Box,
  Container,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    transitionDelay: "0.8s",
  },
  videoSection: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    overflow: "hidden",
  },
  video: {
    position: "absolute",
    bottom: "0",
    right: "0",
    width: "auto",
    height: "auto",
    minHeight: "100%",
    display: "inline-block",
    opacity: "0.45",
    zIndex: -1,
    filter: `grayscale(100%)`,
  },
  inner: {
    paddingLeft: 150,
    paddingRight: 100,

    [theme.breakpoints.down("md")]: {
      paddingLeft: 85,
      paddingRight: 33,
    },
  },
  title: {
    fontSize: "10vw",
    fontWeight: 800,
    marginBottom: "0.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "30vw",
    },
    marginLeft: -5
  },
  subtitle: {
    fontSize: 40,
    fontWeight: 600,
    letterSpacing: -0.5,
  },
  subsubtitle: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: -0.5,
  },
}));

const Header: React.FC = () => {
  const styles = useStyles();
  return (
    <Box className={styles.pageHeader}>
      <div className={styles.videoSection}>
        <video autoPlay loop muted className={styles.video}>
          <source src="/assets/videos/bg-video.mov" type="video/mp4" />
        </video>
      </div>
      <Container className={styles.inner}>
        <Typography variant="h1" className={styles.title}>
          Crave
        </Typography>
        <Typography paragraph className={styles.subtitle}>
          Elevate your online ordering and gift card sales to unimaginable levels.
        </Typography>
        <Typography paragraph className={styles.subsubtitle}>
          Scroll down to learn more.
        </Typography>
      </Container>
    </Box>
  );
};

export default Header;
