import { Box } from "@material-ui/core";
import { Helmet } from "react-helmet-async";

import { DEFAULT_META } from "constants/meta";

const PageMeta = () => {
  const { title, description } = { ...DEFAULT_META };

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
};

const Page: React.FC = ({ children, ...props }) => {
  return (
    <>
      <PageMeta />
      <Box {...props}>{children}</Box>
    </>
  );
};

export default Page;
