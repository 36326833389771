import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { PageLoader } from "components";
import NotFound from "views/NotFound";
import { DefaultLayout } from "views/layouts";

const Home = lazy(() => import("./views/Home"));
const MobilePayment = lazy(() => import("./views/MobilePayment"));
const GiftCard = lazy(() => import("./views/GiftCard"));
const OnlineOrdering = lazy(() => import("./views/OnlineOrdering"));
const MerchantPortal = lazy(() => import("./views/MerchantPortal"));
const AboutUs = lazy(() => import("./views/AboutUs"));
const ContactUs = lazy(() => import("./views/ContactUs"));
const Privacy = lazy(() => import("./views/Privacy"));
const Terms = lazy(() => import("./views/Terms"));

function App() {
  return (
    <BrowserRouter>
      <DefaultLayout>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/mobile-payment" element={<MobilePayment />} />
            <Route path="/gift-card" element={<GiftCard />} />
            <Route path="/online-ordering" element={<OnlineOrdering />} />
            <Route path="/merchant-portal" element={<MerchantPortal />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </Suspense>
      </DefaultLayout>
    </BrowserRouter>
  );
}

export default App;
