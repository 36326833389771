import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  navbar: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "calc(100% - 120px)",
    padding: "30px 40px",
    zIndex: 9,
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "white",
    display: "flex",
    justifyContent: "center",
  },
  hamburger: {
    width: 40,
    height: 40,
    display: "none",
    position: "relative",
    cursor: "pointer",
    pointerEvents: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
    },
  },
  menuContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 auto",
    padding: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: 16,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 600,
    "&:hover, &:active": {
      color: "#EE3C39",
    },
  },
  linkActive: {
    color: "#EE3C39",
  },
  logoImage: {
    height: 90,
    [theme.breakpoints.down("md")]: {
      height: 65,
    },
  },
}));

const Navbar: React.FC = () => {
  const styles = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();

  const navLinks = [
    {
      route: "/mobile-payment",
      name: "Mobile Payment",
    },
    {
      route: "/gift-card",
      name: "Gift Card",
    },
    {
      route: "/online-ordering",
      name: "Online Ordering",
    },
    {
      route: "/merchant-portal",
      name: "Merchant Portal",
    },
    {
      route: "/about-us",
      name: "About Us",
    },
    {
      route: "/contact-us",
      name: "Contact Us",
    },
  ];

  return (
    <nav className={styles.navbar}>
      <div className={styles.hamburger} id="hamburger">
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <MenuIcon />
        </IconButton>
      </div>
      <div className={styles.menuContainer}>
        {navLinks.map((item, index) => (
          <Link
            key={`item-${index}`}
            to={item.route}
            className={
              item.route === location.pathname
                ? `${styles.link} ${styles.linkActive}`
                : styles.link
            }
          >
            {item.name}
          </Link>
        ))}
      </div>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          {navLinks.map((item, index) => (
            <ListItem
              key={`item-${index}`}
              onClick={() => setOpenDrawer(false)}
            >
              <ListItemText>
                <Link
                  to={item.route}
                  className={
                    item.route === location.pathname
                      ? `${styles.link} ${styles.linkActive}`
                      : styles.link
                  }
                >
                  {item.name}
                </Link>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </nav>
  );
};

export default Navbar;
