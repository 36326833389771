import {
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Link } from "react-router-dom";
import {link} from "fs/promises";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 80,
  },
  logoImage: {
    height: 42,
  },
  linkSection: {
    padding: "42px 0",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: 16,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 600,
    "&:hover": {
      color: "#EE3C39",
    },
  },
  copyrightSection: {
    padding: "50px 0",
    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  }
}));

const Footer: React.FC = () => {
  const styles = useStyles();

  return (
    <footer className={styles.root}>
      <Container>
        <Typography variant="h2">
          <img
            src="/assets/images/crave_It_name_only_v2_300_white.png"
            alt="Logo"
            className={styles.logoImage}
          />
        </Typography>
        <Grid container spacing={2} className={styles.linkSection}>
          <Grid item md={4}>
            <h2>Our Products</h2>
            <p>
              <Link to="/mobile-payment" className={styles.link}>
                Mobile Payment
              </Link>
            </p>
            <p>
              <Link to="/gift-card" className={styles.link}>
                Gift Card
              </Link>
            </p>
            <p>
              <Link to="/online-ordering" className={styles.link}>
                Online Ordering
              </Link>
            </p>
            <p>
              <Link to="/merchant-portal" className={styles.link}>
                Merchant Portal
              </Link>
            </p>
          </Grid>
          <Grid item md={4}>
            <h2>Get in touch</h2>
            <p>
              <Link to="/contact-us" className={styles.link}>
                Contact
              </Link>
            </p>
            <p>Email: <a href="mailto:info@gift.it" className={styles.link}>info@gift.it</a></p>
            <p>Phone: <a href="tel:800-715-3130" className={styles.link}>800-715-3130</a></p>
            <p>
              <Link to="/" className={styles.link}>
                <FacebookIcon />
              </Link>
              <Link to="/" className={styles.link}>
                <TwitterIcon />
              </Link>
              <Link to="/" className={styles.link}>
                <InstagramIcon />
              </Link>
              <Link to="/" className={styles.link}>
                <LinkedInIcon />
              </Link>
            </p>
          </Grid>
          <Grid item md={4}>
            <h2>Other</h2>
            <p>
              <Link to="/privacy" className={styles.link}>
                Privacy Policy
              </Link>
            </p>
            <p>
              <Link to="/terms" className={styles.link}>
                Terms of Services
              </Link>
            </p>
          </Grid>
        </Grid>
        <div className={styles.copyrightSection}>
          <Typography variant="caption">© 2021 Crave It</Typography>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
