import {
  Box,
  Container,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

interface ICtaSection {
  background: string;
  title?: string;
  subtitle?: string;
}

const useStyles = makeStyles<Theme, ICtaSection>((theme: Theme) => ({
  pageHeader: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    transitionDelay: "0.8s",
  },
  videoSection: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    overflow: "hidden",
  },
  video: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "auto",
    minHeight: "100%",
    opacity: 0.45,
    WebkitTransform: "translate(-50%, -50%)",
    MozTransform: "translate(-50%, -50%)",
    MsTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    display: "inline-block",
    backgroundImage: ({ background }) => `url(/assets/images/${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    zIndex: -1,
    filter: `grayscale(100%)`,
  },
  inner: {
    paddingLeft: 150,
    paddingRight: 100,

    [theme.breakpoints.down("md")]: {
      paddingLeft: 85,
      paddingRight: 33,
    },
  },
  title: {
    fontSize: "6vw",
    fontWeight: 800,
    marginBottom: "0.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "10vw",
      marginLeft:0
    },
    marginLeft: -5
  },
  subtitle: {
    fontSize: 25,
    fontWeight: 600,
    letterSpacing: -0.5,
    [theme.breakpoints.down("md")]: {
      fontSize: 18
    },
  },
}));

const CtaSection: React.FC<ICtaSection> = ({
  background,
  title = "",
  subtitle = "",
}) => {
  const styles = useStyles({ background });

  return (
    <Box className={styles.pageHeader}>
      <div className={styles.videoSection}>
        <div className={styles.video}></div>
      </div>
      <Container className={styles.inner}>
        {title && (
          <Typography variant="h1" className={styles.title}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography paragraph className={styles.subtitle}>
            {subtitle}
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default CtaSection;
