import React, { useState } from "react";
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  Theme,
} from "@material-ui/core";
import { darkTheme, lightTheme } from "theme";

const ThemeContext = React.createContext({
  isDark: true,
  toggleDarkMode: () => {},
});

const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(darkTheme);
  const isDark = theme.palette.type === "dark";

  const toggleDarkMode = () => {
    setTheme(isDark ? { ...lightTheme } : { ...darkTheme });
  };

  return (
    <ThemeContext.Provider value={{ isDark, toggleDarkMode }}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </StylesProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
