import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const PageLoader: React.FC = () => {
  return (
    <Box>
      <CircularProgress />
    </Box>
  );
};

export default PageLoader;
